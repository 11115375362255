import React, { useRef } from "react"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import LuxonUtils from "@date-io/luxon"
import { ViewMealPlan } from "./ViewMealPlan"
import { Tooltip, Typography, Fab, Grid } from "@material-ui/core"
import { Add } from "@material-ui/icons"

export function CreateMealPlan(props) {
  const { shadow, mealPlan, days, meals, addMeal, removeMeal, createMealPlan, setDate } = props

  const ref = useRef()

  return (
    <Grid container spacing={2} style={{ margin: 0, width: "100%" }} ref={ref}>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <DatePicker
            inputVariant="outlined"
            value={mealPlan.startDate}
            format="yyyy-MM-dd"
            onChange={setDate}
            variant="inline"
            orientation="landscape"
            label="Select start day"
            PopoverProps={{ container: ref.current, disablePortal: true }}
            shouldDisableDate={(date) => {
              return date.weekday !== 1
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        <ViewMealPlan
          shadowRoot={shadow}
          editable={true}
          mealPlan={mealPlan}
          days={days}
          meals={meals}
          addMeal={addMeal}
          removeMeal={removeMeal}
        />
      </Grid>

      <Grid item xs={12}>
        <Tooltip title={<Typography>{mealPlan.id ? "Update Meal Plan" : "Create Meal Plan"}</Typography>}>
          <Fab
            variant="extended"
            color="primary"
            onClick={(event) => {
              event.stopPropagation()
              createMealPlan()
            }}
          >
            <Add />
            {mealPlan.id ? "Update Meal Plan" : "Create Meal Plan"}
          </Fab>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
