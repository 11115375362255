import { DateTime } from "luxon"
import React from "react"
import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { CreateMealPlan as ReactCreateMealPlan } from "../React/CreateMealPlan"

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
const meals = ["Lunch", "Tea"]

export default class CreateMealPlan extends ReactMaterialComponentBase {
  constructor() {
    super()

    /** @type {import("../Types").MealPlan} */
    this.mealPlan = { meals: [], startDate: DateTime.now().setZone("utc").startOf("week").toISO() }

    this.addingMeal = null

    this.addEventListener("recipe-selected", this.recipeSelected)
    this.addEventListener("reason-selected", this.reasonSelected)

    this.jsxRootComponent = () => (
      <ReactCreateMealPlan
        shadow={this.shadow}
        addMeal={(mealInDay, dayOfWeek) => {
          this.addingMeal = {
            mealInDay,
            dayOfWeek,
          }

          this.render()
        }}
        createMealPlan={() => this.createMealPlan()}
        setDate={(date) => {
          this.mealPlan.startDate = date.setZone("utc").startOf("week").toISO()

          this.mealPlan.meals.map((meal) => {
            meal.date = DateTime.fromISO(this.mealPlan.startDate).plus({ days: meal.day }).toISO()
          })

          this.render()
        }}
        days={days}
        meals={meals}
        mealPlan={this.mealPlan}
        removeMeal={(mealInDay, dayOfWeek) => this.removeMeal(mealInDay, dayOfWeek)}
      />
    )
  }

  connectedCallback() {
    super.connectedCallback() && super.connectedCallback()
  }

  /**
   * @param {CustomEvent} event
   */
  recipeSelected(event) {
    /**
     * @type {import("../Types").Recipe}
     */
    const recipe = event.detail.recipe

    if (!this.addingMeal) {
      return
    }

    const { mealPlan } = this

    const date = DateTime.fromISO(mealPlan.startDate).plus({ days: this.addingMeal.dayOfWeek })

    mealPlan.meals.push({
      recipe,
      day: this.addingMeal.dayOfWeek,
      date: date.toISO(),
      time: meals[this.addingMeal.mealInDay].toLocaleLowerCase(),
    })

    this.addingMeal = null

    this.mealPlan = { ...mealPlan }

    this.render()
  }

  /**
   * @param {CustomEvent} event
   */
  reasonSelected(event) {
    const reason = event.detail.reason

    if (!this.addingMeal) {
      return
    }

    const { mealPlan } = this

    const date = DateTime.fromISO(mealPlan.startDate).plus({ days: this.addingMeal.dayOfWeek })

    mealPlan.meals.push({
      reason,
      day: this.addingMeal.dayOfWeek,
      date: date.toISO(),
      time: meals[this.addingMeal.mealInDay].toLocaleLowerCase(),
    })

    this.addingMeal = null

    this.mealPlan = { ...mealPlan }

    this.render()
  }

  removeMeal(mealInDay, dayOfWeek) {
    const { mealPlan } = this

    mealPlan.meals = mealPlan.meals.filter((meal) => {
      const mealDate = DateTime.fromISO(meal.date)
      const removingMealDate = DateTime.fromISO(mealPlan.startDate).plus({ days: dayOfWeek })

      const mealItemNoMatch = meal.time !== meals[mealInDay].toLocaleLowerCase()
      const mealTimeNoMatch = mealDate.toMillis() !== removingMealDate.toMillis()

      const noMealMatch = mealItemNoMatch || mealTimeNoMatch

      return noMealMatch
    })

    this.mealPlan = { ...mealPlan }

    this.render()
  }

  async createMealPlan() {
    try {
      const token = await window.authorise()

      const createdMealPlan = await fetch(`${this.getAttribute("target")}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(this.mealPlan),
      })

      if (!createdMealPlan.ok) {
        throw Error("Failed to create meal plan")
      }

      this.onSuccess && this.onSuccess("Meal plan created")
    } catch (error) {
      this.onError && this.onError("Failed to create meal plan")
    }
  }
}

customElements.define("create-meal-plan", CreateMealPlan)
